import appStrings from '@/app/utility/string.utility';
export default {
  name: 'addAutoAccounting',
  components: {
    appStrings
  },
  props: ['rowDetails'],
  data() {
    return {
      unsubscribe: null,
      accountingIndex: 0,
      showValueSetModal: false,
      isSuccess: false,
      showAlert: false,
      loader: null,
      editMode: false,
      vsetCode: null,
      accConstant: null,
      responseMsg: '',
      parent_value_set_id: null,
      addLeName: {
        value: null,
        text: null
      },
      addOuName: {
        value: null,
        text: null
      },
      addAccountingType: {
        value: null,
        text: null
      },
      autoAccountingHdrId: 0,
      account_id: 0,
      addAccountingData: [],
      addAccountingFields: [
        {
          key: 'segment'
        },
        {
          key: 'segment_name'
        },
        {
          key: 'table_code_meaning',
          label: 'Table Name'
        },
        {
          key: 'const_meaning',
          label: 'Constant'
        },
        {
          key: 'constant',
          class: 'd-none'
        },
        {
          key: 'fms_auto_accounting_rule_dtl_id',
          class: 'd-none'
        },
        {
          key: 'tables_vset_code',
          class: 'd-none'
        }
      ]
    };
  },
  mounted() {
    if (this.rowDetails) {
      this.autoAccountingHdrId = this.rowDetails.auto_acc_rule_hdr_id;
      this.getAutoAccountingListById(this.autoAccountingHdrId);
      // this.fillRecordFromAutoAccountParent(this.rowDetails);
    } else {
      this.editMode = true;
    }
    if (this.addAccountingData.length < 7) {
      this.segmentAdd();
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditAutoAccountingData();
        }
      }
    });
  },
  methods: {
    getAutoAccountingListById(autoAccountingHdrId) {
      const payload = autoAccountingHdrId;
      this.loader = true;
      this.$store
        .dispatch('masterData/getAutoAccountingListById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.addLeName.value = results.le_id;
            this.addLeName.text = results.le_name;
            this.addOuName.value = results.ou_id;
            this.addOuName.text = results.ou_name;
            this.addAccountingType.value = results.type_vset_code;
            this.addAccountingType.text = results.accounting_type;
            this.addAccountingData = results.accounting_details;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditAutoAccountingData() {
      const details = this.addAccountingData.map(key => {
        return {
          accounting_rule_dtl_id: key.fms_auto_accounting_rule_dtl_id,
          constant: key.constant,
          segment: key.segment,
          table_vset_code: key.tables_vset_code
        };
      });
      const payload = {
        account_vset_code: this.addAccountingType.value,
        accounting_rule_hdr_id: this.autoAccountingHdrId,
        ou_id: this.addOuName.value,
        accounting_details: details ? details : null
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/addEditAutoAccountingData', payload)
        .then(response => {
          this.loader = false;
          this.showAlert = true;
          if (response.status === 200) {
            this.editMode = false;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            const accountingDtl = response.data.data;
            this.addAccountingType = {
              value: accountingDtl.type_vset_code,
              text: accountingDtl.accounting_type
            };
            this.autoAccountingHdrId =
              accountingDtl.fms_auto_accounting_rule_hdr_id;
            this.addAccountingData = accountingDtl.accounting_details.map(
              item => {
                return {
                  segment: item.segment,
                  segment_name: item.segment_name,
                  table_code_meaning: item.table_code_meaning,
                  const_meaning: item.const_meaning,
                  constant: item.constant,
                  tables_vset_code: item.tables_vset_code,
                  fms_auto_accounting_rule_dtl_id:
                    item.fms_auto_accounting_rule_dtl_id
                };
              }
            );
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    fillRecordFromAutoAccountParent(item) {
      this.addLeName.value = item.le_id;
      this.addLeName.text = item.le_name;
      this.addOuName.value = item.ou_id;
      this.addOuName.text = item.ou_name;
      this.addAccountingType.value = item.acc_type_code;
      this.addAccountingType.text = item.acc_type_meaning;
      this.autoAccountingHdrId = item.auto_acc_rule_hdr_id;
      this.getAutoAccountingListById(this.autoAccountingHdrId);
    },
    segmentAdd() {
      for (let i = 1; i <= 7; i += 1) {
        this.addAccountingData.push({
          segment: 'SEGMENT' + i,
          segment_name: null,
          table_code_meaning: null,
          const_meaning: null,
          constant: null,
          tables_vset_code: null,
          accounting_rule_dtl_id: 0
        });
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.addLeName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.parent_value_set_id = this.addLeName.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.addOuName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === 'AUTO_ACCOUNTING_RULE_TYPE') {
        this.addAccountingType = {
          value: item.value_code,
          text: item.value_meaning
        };
      } else if (this.vsetCode === 'VATIKA_GL_Company') {
        this.addAccountingData[this.accountingIndex].constant = item.value_code;
        this.addAccountingData[this.accountingIndex].const_meaning =
          item.value_meaning;
      } else if (this.vsetCode === 'VATIKA_GL_Line of Business') {
        this.addAccountingData[this.accountingIndex].constant = item.value_code;
        this.addAccountingData[this.accountingIndex].const_meaning =
          item.value_meaning;
      } else if (this.vsetCode === 'VATIKA_GL_Department') {
        this.addAccountingData[this.accountingIndex].constant = item.value_code;
        this.addAccountingData[this.accountingIndex].const_meaning =
          item.value_meaning;
      } else if (this.vsetCode === 'VATIKA_GL_OU') {
        this.addAccountingData[this.accountingIndex].constant = item.value_code;
        this.addAccountingData[this.accountingIndex].const_meaning =
          item.value_meaning;
      } else if (this.vsetCode === 'FMS_AC_RULE_TABLE') {
        this.addAccountingData[this.accountingIndex].tables_vset_code =
          item.value_code;
        this.addAccountingData[this.accountingIndex].table_code_meaning =
          item.value_meaning;
      }
    },
    openTableValueSet(index) {
      this.accountingIndex = index;
      const tableValue = this.addAccountingData[this.accountingIndex].segment;
      if (tableValue === 'SEGMENT5' || tableValue === 'SEGMENT6') {
        this.openValueSetModal('FMS_AC_RULE_TABLE');
      }
    },
    openConstantValueSet(index) {
      this.accountingIndex = index;
      const segmentValue = this.addAccountingData[this.accountingIndex].segment;
      if (segmentValue === 'SEGMENT1') {
        this.openValueSetModal('VATIKA_GL_Company');
      } else if (segmentValue === 'SEGMENT2') {
        this.openValueSetModal('VATIKA_GL_Line of Business');
      } else if (segmentValue === 'SEGMENT3') {
        this.openValueSetModal('VATIKA_GL_Department');
      } else if (segmentValue === 'SEGMENT4') {
        this.openValueSetModal('VATIKA_GL_OU');
      }
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === 'AUTO_ACCOUNTING_RULE_TYPE') {
        this.parent_value_set_id = null;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
