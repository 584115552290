import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import addFmsAcc from '../autoAccounting/addAutoAccounting';
import { required } from 'vuelidate/lib/validators';
export default {
  name: 'autoAccounting',
  watch: {
    currentPage: function() {
      this.getAutoAccountingList();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getAutoAccountingList();
    }
  },
  components: {
    commonHelper,
    addFmsAcc
  },
  data() {
    return {
      unsubscribe: null,
      payload: {},
      rowDetails: null,
      showFmsAccModal: false,
      parent_value_set_id: null,
      showValueSetModal: false,
      loader: false,
      currentPage: 1,
      totalRows: null,
      vsetCode: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      leName: {
        value: null,
        text: null
      },
      ouName: {
        value: null,
        text: null
      },
      accountingType: {
        value: null,
        text: null
      },
      accountingData: [],
      accountingFields: [
        {
          key: 'le_id',
          class: 'd-none'
        },
        {
          key: 'ou_id',
          class: 'd-none'
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'ou_name',
          label: 'Operating Unit'
        },
        {
          key: 'acc_type_code',
          class: 'd-none'
        },
        {
          key: 'acc_type_meaning',
          label: 'Account Type'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        },
        {
          key: 'last_updated_by'
        },
        {
          key: 'last_updated_date'
        },
        {
          key: 'auto_acc_rule_hdr_id',
          class: 'd-none'
        },
        {
          key: 'acc_type_vset_name',
          class: 'd-none'
        }
      ]
    };
  },
  validations: {
    leName: {
      text: {
        required
      }
    }
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showFmsAccModal = true;
          this.rowDetails = null;
        }
        if (actionName === 'download' && !this.showFmsAccModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'masterData/getAutoAccountingList',
            'auto-accounting',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getAutoAccountingList() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        le_id: this.leName.value,
        ou_id: this.ouName.value,
        accounting_type: this.accountingType.value
      };
      this.loader = true;
      this.$store
        .dispatch('masterData/getAutoAccountingList', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.accountingData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
      }
    },
    rowSelected(item) {
      this.rowDetails = item;
      this.showFmsAccModal = true;
    },
    // rowSelected(rowData) {
    //   this.$emit('getFlexField', rowData);
    //   if (!this.showModal) {
    //     this.showFlexFieldModal = true;
    //     setTimeout(() => {
    //       this.eventBus.$emit('ffDetails', rowData);
    //     }, 0);
    //     this.flexfieldDetails = rowData;
    //   }
    // },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.parent_value_set_id = this.leName.value;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.OU_LIST) {
        this.ouName = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      } else if (this.vsetCode === 'AUTO_ACCOUNTING_RULE_TYPE') {
        this.accountingType = {
          value: item.value_code,
          text: item.value_meaning
        };
      }
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === 'AUTO_ACCOUNTING_RULE_TYPE') {
        this.parent_value_set_id = null;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    hideChildModal(flag) {
      this.showValueSetModal = flag;
    },
    resetSearchFilters() {
      this.leName = {
        value: null,
        text: null
      };
      this.ouName = {
        value: null,
        text: null
      };
      this.accountingType = {
        value: null,
        text: null
      };
      this.parent_value_set_id = null;
      this.accountingData = [];
    }
  },

  beforeDestroy() {
    this.unsubscribe();
  }
};
